<template>
  <div>
    <div class="ticketTextHeading">Graph</div>
    <br />
    <br />
    <v-btn
      color="#38227A"
      dark
      class="px-11 text-capitalize"
      style="border-radius: 10px; margin: 5px; width: 130px"
      height="45px"
      @click="toggleGraphModal({ show: true, type: 'add' })"
    >
      <span>Offline Data</span>
    </v-btn>
    <v-btn @click="toggleView" color="#38227A" dark class="px-11 text-capitalize"
      style="border-radius: 10px; margin: 5px; width: 130px"
      height="45px">
      <span>{{ showTable ? 'Show Graph' : 'Show Table' }}</span>
    </v-btn>
    <div v-show="!showTable">
      <canvas ref="lineChart" style="height: 400px"></canvas>
    </div>
    <graph-modal></graph-modal>
    <div v-if="showTable" class="data-table">
      <v-simple-table class="elevation-8 custom-table" style="margin-top: 2%">
        <template v-slot:default>
          <thead>
            <tr class="table-header">
              <th style="width: 200px">Details</th>
              <th
                class="textHeader text-center"
                v-for="(item, i) in labels"
                :key="i"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableresponseData.rows" :key="i">
              <td class="date-cell">
                <div class="date-info">
                  <span class="date-label">Assembly Date:</span>
                  <span class="date-value">{{ item.assembly_date }}</span>
                </div>
                <div class="date-info">
                  <span class="date-label">Game Date:</span>
                  <span class="date-value">{{ item.game_date }}</span>
                </div>
              </td>
              <td
                class="text-center value-cell"
                v-for="(rowval, i) in item.values"
                :key="i"
              >
                {{ rowval }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { mapActions } from "vuex";
import { GAME_GRAPH_API, GAME_TABLE_API} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";

export default {
  components: {
    GraphModal: () => import("../GraphModal/GraphModal"),
  },
  data() {
    return {
      colorMap: {}, // To store colors for each game date
      graphgameid: "",
      showTable: false,
      tableresponseData: {},
      labels: [],
    };
  },
  mounted() {
    this.getOverViewDonationData();
    this.getTblGraphData();
  },
  methods: {
    ...mapActions({
      toggleGraphModal: "gameManagement/toggleGraphModal",
    }),
    fetchGraphData() {
      // Simulate API response
      const responseData = {
        success: true,
        message: "success",
        labels: [
          "days_45",
          "days_30",
          "days_21",
          "days_14",
          "days_10",
          "days_07",
          "days_03",
          "days_01",
          "game_day",
          "final",
        ],
        graph_data: [
          {
            game_date: "2024-03-16",
            data: [0, 155, 185, 208, 316, 367, 482, 547, 575, 575],
          },
          {
            game_date: "2024-03-16",
            data: [0, 99, 102, 114, 180, 211, 288, 355, 371, 387],
          },
        ],
      };

      // Extracting labels and data from the response
      const labels = responseData.labels;
      const graphData = responseData.graph_data.map((item) => {
        return {
          label: item.game_date,
          data: item.data,
        };
      });

      // Rendering the line chart
      this.renderLineChart(labels, graphData);
    },

    getTblGraphData(){
      const successHandler= (res) => {
        this.loaded = true;
        this.tableresponseData = res.data.table_data;
        this.labels = this.tableresponseData.columns.map((label) => {
          return label
          .replace("days_45", "Days 45")
          .replace("days_30", "Days 30")
          .replace("days_21", "Days 21")
          .replace("days_14", "Days 14")
          .replace("days_10", "Days 10")
          .replace("days_07", "Days 07")
          .replace("days_03", "Days 03")
          .replace("days_01", "Days 01")
          .replace("game_day", "Game Day")
          .replace("final", "Final")
          .replace("door_sale", "Door sale");
        });
      };


      let tableData = {};
      tableData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_TABLE_API,
        tableData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );

      const failureHandler = (res) => {
        console.log(res);
      };
    },
    getOverViewDonationData() {
      const successHandler = (res) => {
        this.loaded = true;
        const responseData = res.data;
        // this.graphgameid=res.data.graph_data[0].id;
        const labels = responseData.labels.map((label) => {
          return label
            .replace("days_", " ")
            .replace("final", "online close")
            .replace("door_sale", "final");
        });

        const graphData = responseData.graph_data.flatMap((item) => {
          const color = this.getColorForGameDate(item.game_date);
          let result = [
            {
              label: "Game On " + item.game_date,
              data: item.data,
              color: color,
            },
          ];
          if (item.assembly_info) {
            const { assembly_date, label_index } = item.assembly_info;
            labels[
              label_index
            ] = `${labels[label_index]} (Assembly: ${assembly_date})`;
            result.push({
              label: `Assembly on ${assembly_date}`,
              data: item.data, // Adjust data accordingly if it's different for the assembly
              color: color,
            });
          }
          return result;
        });

        // Rendering the line chart
        this.renderLineChart(labels, graphData);
      };
      
      const failureHandler = (res) => {
        console.log(res);
      };

      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        GAME_GRAPH_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );

    },
    toggleView() {
      this.showTable = !this.showTable;
    },
    renderLineChart(labels, graphData) {
      const ctx = this.$refs.lineChart.getContext("2d");

      new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: graphData.map((data) => {
            return {
              label: data.label,
              data: data.data,
              fill: false,
              borderColor: data.color,
              borderWidth: 2,
            };
          }),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Days",
                  fontSize: 14, // Set font size here
                  fontStyle: "bold", // Set font style to bold here
                },
                 ticks: {
            maxRotation: 0, // This ensures labels are not slanted
            minRotation: 0,
          },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
    getColorForGameDate(gameDate) {
      // Check if the color for this game date is already assigned
      if (!this.colorMap[gameDate]) {
        // Assign a new random color and store it in the colorMap
        this.colorMap[gameDate] = getRandomColor();
      }
      return this.colorMap[gameDate];
    },
  },
};

// Function to generate random color
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
</script>

<style scoped>
/* Add any custom styles here */
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background: #e7e5ed;
  font-weight: bold;
}

.date-cell {
  padding: 10px;
  background-color: #f9f9f9;
  font-size: 14px;
}

.date-info {
  margin-bottom: 5px;
}

.date-label {
  font-weight: bold;
  font-size: 13px;
  color: #6c757d;
}

.date-value {
  display: inline-block;
  margin-left: 5px;
  color: #333;
}

.value-cell {
  padding: 10px;
  font-size: 16px;
  background-color: #ffffff;
}

.elevation-8 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.textHeader {
  padding: 10px;
  font-size: 16px;
  color: #333;
}
</style>
